import store from '@/store';
import CollectionsAPI from '@/services/graphql/collections';

/**
 * Fetch the members grant information for a specific collection
 * to reduce the time of loading for initial page
 *
 * Also updates the list of collections in vuex store with this info.
 *
 * @param {Object} item selected collection
 * @param {Object} collectionGroup collection group object
 * @returns {Object} item with member grant informaiton added
  */
async function updateMembersInfoForCollection(collection, collectionGroup) {
  if (collection.members && collection.members[0].directGrant) return collection;

  try {
    store.commit('spinner', true);

    const response = await CollectionsAPI.getMembersWithGrants({
      collectionId: collection.id,
      collectionName: collection.name,
      workspaceId: store.state.Workspace.activeWorkspaceId,
    });
    const members = response.data.response;
    const updatedCollection = {
      ...collection,
      members,
    };

    let collectionList = store.state.Collections[collectionGroup.list];
    collectionList = collectionList.map(c => c.id === collection.id ? updatedCollection : c);
    store.commit(`Collections/${collectionGroup.mutationName}`, collectionList);

    return updatedCollection;

  } catch (err) {
    console.error(`Error in fetching the grant permissions for coll: ${collection}`, err);

  } finally {
    store.commit('spinner', false);
  }
}

export { updateMembersInfoForCollection };