var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.key,staticClass:"table-cell-wrapper fs-16 gap-2",class:[{
    'inline-field': _vm.isExpandedMode,
    'table-cell-wrapper__selected': _vm.isSelectedCell && _vm.allowShowSelectCol,
    'table-cell-wrapper__selected--one': _vm.isOneCellSelected,
    'table-cell-wrapper__attachment': _vm.isAttachmentCell,
  }],on:{"keydown":_vm.editActions,"click":[function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.onExactClick.apply(null, arguments)},function($event){if(!$event.ctrlKey){ return null; }if($event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.setMultipleCtrlCells.apply(null, arguments)},function($event){if(!$event.shiftKey){ return null; }if($event.ctrlKey||$event.altKey||$event.metaKey){ return null; }return _vm.setMultipleShiftCells.apply(null, arguments)},function($event){if(!$event.metaKey){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey){ return null; }return _vm.setMultipleCtrlCells.apply(null, arguments)}],"mousedown":_vm.onSetKeyboardControllerDisabled,"dblclick":_vm.doubleClickCell}},[_c('div',{staticClass:"d-flex align-center justify-space-between",class:( _obj = {}, _obj[("table-cell-buttons table-cell-buttons__" + _vm.selectedViewCriteria)] = !_vm.isOpenedInDialog, _obj['table-cell-buttons-editing'] =  _vm.editMode, _obj )},[(!_vm.hideHeader)?_c('div',{staticClass:"fs-12 mr-2 font-weight-bold font-fam-poppins darkGrey--text text-uppercase"},[_vm._v(" "+_vm._s(_vm.header.text)+" "),(_vm.isRequired)?_c('span',{staticClass:"red--text"},[_vm._v("*")]):_vm._e()]):_c('v-spacer'),_c('div',{staticClass:"d-flex align-center px-3 gap-1 rounded-pill",class:{ 'table-cell-buttons-inner py-2': !_vm.isOpenedInDialog }},[_vm._t("cellActions",null,{"doubleClickCell":_vm.doubleClickCell}),(!_vm.hideActions)?_vm._t("additionalCellActions",null,{"borderedCell":_vm.borderedCell,"isCellForPasting":_vm.isPasteCell}):_vm._e()],2),(!_vm.isExpandedMode)?_c('div',{staticClass:"py-2"},[_vm._t("cellNotification")],2):_vm._e()],1),_c('div',{staticClass:"table-cell-wrapper__selected-wrapper",class:{
      'table-cell-wrapper__selected--one-overlay': _vm.isSelectedCell && _vm.allowShowSelectCol && _vm.isAllowedToSelectOne,
      'table-cell-wrapper__selected--edited-cell' : _vm.isOverlayedEditCell
    },staticStyle:{"height":"100%"}},[(_vm.component)?_c('div',{staticClass:"component-wrapper",class:{ 'position-relative': _vm.isLoading && _vm.isExpandedMode }},[_c(_vm.component,_vm._g({ref:_vm.getRefOfCell,tag:"component",attrs:{"bordered-cell":_vm.borderedCell,"col":_vm.columnName,"collection-id":_vm.collectionId,"current-row-id":_vm.currentRowId,"disabled-edit-mode":_vm.disabledEditMode,"edit-mode":_vm.editMode,"expanded-row":_vm.expandedRow,"has-access-editor":_vm.hasAccessToField,"header":_vm.header,"hidden-row":_vm.hiddenRow,"hint":_vm.header.column.hint,"is-array":_vm.isHeaderTypeArray,"is-expanded-mode":_vm.isExpandedMode,"is-dialog-collection":_vm.isDialogCollection,"is-opened-in-dialog":_vm.isOpenedInDialog,"is-overlayed":_vm.isOneCellSelected,"item":_vm.item,"mapped-dropdowns-of-view":_vm.mappedDropdownsOfView,"open-link-in-new-tab":_vm.openLinkInNewTab,"select-items":_vm.header.items ? _vm.header.items : [],"should-expand-height":_vm.shouldExpandHeight,"show-pensil":_vm.showPensil,"style-filled-img":_vm.styleFilledImg,"table-id":_vm.scheduleId,"transform-images-to-data":_vm.transformImagesToData,"value":_vm.value,"view":_vm.view},on:{"messagesNoHasAccessEditor":_vm.messagesNoHasAccessEditor,"dblclickHandler":_vm.doubleClickCell,"toggleEditMode":_vm.toggleEditMode,"setEditMode":_vm.setEditMode,"change":function($event){return _vm.updateCell($event, _vm.columnName, _vm.currentRowId)},"saveFileProcess":_vm.onUpdateImages}},_vm.$listeners)),(_vm.isLoading)?_c('div',{staticClass:"loading-overlay"},[_c('img',{staticClass:"loading-overlay-spinner",attrs:{"src":require("@/assets/icons/circle-loader.gif")}})]):_vm._e()],1):_c('div',[_vm._v(" "+_vm._s(Array.isArray(_vm.value) ? _vm.value.join(', ') : _vm.value)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }